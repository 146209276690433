import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

import getIcon from "@/utils/get-icon";

const ListModulesHeader = ({
  onSearchChange,
  searchValue,
  handleAddModuleClick,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: 2,
        mb: 2,
      }}
    >
      <Paper
        component="form"
        elevation={1}
        sx={{
          p: "2px 2px",
          display: "flex",
          alignItems: "center",
          borderRadius: 2,
          width: isSmallScreen ? "100%" : "60%",
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search modules"
          inputProps={{ "aria-label": "search modules" }}
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box
        sx={{
          width: isSmallScreen ? "100%" : "auto",
          textAlign: isSmallScreen ? "center" : "right",
        }}
      >
        <Tooltip title="Add module" placement="bottom">
          <Button
            startIcon={getIcon("add")}
            color="primary"
            variant="contained"
            endIcon={getIcon("dashboard")}
            className={"bn-add-module"}
            sx={{
              fontWeight: 700,
              width: isSmallScreen ? "100%" : "auto",
            }}
            onClick={() => handleAddModuleClick()}
          >
            Add
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

ListModulesHeader.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  handleAddModuleClick: PropTypes.func.isRequired,
};

export default ListModulesHeader;
