import React from "react";

import {
  LibraryAdd as LibraryAddIcon,
  Link as LinkIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Box, Divider, MenuItem, Tooltip, Typography } from "@mui/material";
import {
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import PropTypes from "prop-types";

import DropdownButton from "@/components/shared/DropdownButton";
import { toTitleCase } from "@/utils/common-utils";

export const getRawColumns = (isMobile) => [
  {
    accessorKey: "source_name",
    header: "Name",
    Cell: ({ cell }) => (
      <Tooltip title={toTitleCase(cell.getValue())}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: 400,
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "13px",
              xl: "14px",
            },
          }}
          variant="body1"
        >
          {cell.getValue()}
        </Typography>
      </Tooltip>
    ),
    minSize: 200,
    maxSize: 400,
    size: isMobile ? 150 : 200, // Adjust based on screen size
  },
  {
    accessorKey: "source_type",
    header: "Type",
    Cell: ({ cell }) => (
      <Typography
        sx={{
          textAlign: "left",
          fontWeight: 400,
          fontSize: {
            xs: "10px",
            sm: "11px",
            md: "12px",
            lg: "13px",
            xl: "14px",
          },
        }}
        variant="body1"
      >
        {toTitleCase(cell.getValue())}
      </Typography>
    ),
    minSize: 100,
    maxSize: 200,
    size: isMobile ? 100 : 150,
  },
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => (
      <Typography
        sx={{
          textAlign: "left",
          fontWeight: 400,
          fontSize: {
            xs: "10px",
            sm: "11px",
            md: "12px",
            lg: "13px",
            xl: "14px",
          },
        }}
        variant="body1"
      >
        {cell.getValue() ? "✅" : "❌"}
      </Typography>
    ),
    minSize: 50,
    maxSize: 100,
    size: isMobile ? 100 : 150,
  },
  {
    accessorKey: "created_at",
    header: "Added",
    Cell: ({ cell }) => {
      const date = new Date(cell.getValue());
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });

      return (
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: 400,
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "13px",
              xl: "14px",
            },
          }}
          variant="body1"
        >
          {formattedDate}
        </Typography>
      );
    },
    minSize: 150,
    maxSize: 200,
    size: isMobile ? 100 : 150,
  },
];

export const SourceTableProps = (
  sources,
  isMobile,
  loading,
  buttonOptions,
  handleOptionClick,
  rowMenuOptions,
  toolbarButtonDisabled = false,
) => {
  const props = {
    layoutMode: "grid",
    enableRowSelection: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableGlobalFilter: sources.length > 0,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: isMobile ? 5 : 10,
      },
      showGlobalFilter: true,
      density: isMobile ? "compact" : "comfortable",
      columnPinning: { right: ["mrt-row-actions"] },
    },
    positionGlobalFilter: isMobile ? "left" : "right",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        overflowX: "auto", // Allow horizontal scrolling
      },
    },
    getRowId: (originalRow) => originalRow._id,

    state: {
      showSkeletons: loading,
    },
    renderEmptyRowsFallback: () => (
      <Typography textAlign="center" mt={8}>
        No source added.
      </Typography>
    ),
    enableBottomToolbar: sources.length > 0,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        display: "table",
        minWidth: isMobile ? "100%" : "auto", // Adjust for mobile screens
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTableFooterRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        wordBreak: "break-word",
      },
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      if (rowMenuOptions && rowMenuOptions.length > 0) {
        return rowMenuOptions.map((option, index) => [
          // Add Divider before the last item
          index === rowMenuOptions.length - 1 && (
            <Divider key={`divider-${index}`} sx={{ my: 1 }} />
          ),
          <MenuItem
            key={index}
            onClick={() => {
              if (!option.disabled) {
                // Prevent click if disabled
                option.onClick(row);
                closeMenu();
              }
            }}
            disabled={option.disabled} // Disable the MenuItem if required
            sx={{
              ml: 2,
              mr: 2,
              my: 1,
              py: 1,
              color: option.disabled ? "gray" : "inherit", // Optional: gray out text for disabled
            }}
          >
            {option.icon} {option.label}
          </MenuItem>,
        ]);
      }

      return null; // Return null if there are no rowMenuOptions
    },
  };

  // Conditionally add renderTopToolbar if buttonOptions are provided
  if (buttonOptions && buttonOptions.length > 0) {
    props.renderTopToolbar = ({ table }) => {
      return (
        <Box
          gap={2}
          mb={4}
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Box width="100%">
            <DropdownButton
              buttonLabel="Add Materials"
              buttonIcon={
                <LibraryAddIcon sx={{ width: "0.7em", height: "0.7em" }} />
              }
              options={buttonOptions}
              onOptionClick={handleOptionClick}
              sx={{ width: "100%" }}
              disabled={toolbarButtonDisabled}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
            gap={2}
          >
            <MRT_GlobalFilterTextField
              table={table}
              fullWidth={isMobile}
              sx={{
                borderRadius: "8px",
              }}
            />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
        </Box>
      );
    };
  }

  return props;
};

export const getModuleSourceButtonOptions = () => {
  return [
    { label: "Add from Library", icon: <LibraryAddIcon />, key: "add" },
    { label: "Upload File", icon: <UploadFileIcon />, key: "uploadFile" },
    { label: "Add URL", icon: <LinkIcon />, key: "addUrl" },
  ];
};

export const getModuleSourceRowActions = (handleOptionClick) => {
  return [
    {
      label: "View Material",
      icon: <CloudDownloadOutlinedIcon sx={{ mr: 1 }} />,
      onClick: (row) => handleOptionClick(row, "view"),
    },
    {
      label: "Generate Study Cards (Coming Soon)",
      icon: <TextSnippetOutlinedIcon sx={{ mr: 1, color: "gray" }} />,
      onClick: () => {},
      disabled: true,
    },
    {
      label: "Generate Tests (Coming Soon)",
      icon: <QuizOutlinedIcon sx={{ mr: 1, color: "gray" }} />,
      onClick: () => {},
      disabled: true,
    },
    {
      label: "Delete Material",
      icon: <DeleteIcon sx={{ mr: 1 }} color="error" />,
      onClick: (row) => handleOptionClick(row, "delete"),
    },
  ];
};

export const getLibrarySourceButtonOptions = () => {
  return [
    { label: "Upload Files", icon: <UploadFileIcon />, key: "uploadFile" },
    { label: "Add URL", icon: <LinkIcon />, key: "addUrl" },
  ];
};

export const getLibrarySourceRowActions = (handleOptionClick) => {
  return [
    {
      label: "View Source",
      icon: <CloudDownloadOutlinedIcon sx={{ mr: 1 }} />,
      onClick: (row) => handleOptionClick(row, "view"),
    },
    {
      label: "Delete Source",
      icon: <DeleteIcon sx={{ mr: 1 }} color="error" />,
      onClick: (row) => handleOptionClick(row, "delete"),
    },
  ];
};

getModuleSourceRowActions.propTypes = {
  handleOptionClick: PropTypes.func.isRequired,
};

getLibrarySourceRowActions.propTypes = {
  handleOptionClick: PropTypes.func.isRequired,
};

getRawColumns.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

SourceTableProps.propTypes = {
  sources: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  handleOptionClick: PropTypes.func,
  rowMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  toolbarButtonDisabled: PropTypes.bool,
};
