// // UserProfile.js
import { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  Box,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useIntercom } from "react-use-intercom";

import { get_feedback_url } from "@/api/moduleApi";
import getIcon from "@/utils/get-icon";

export default function UserActions({ onClickAction, userActions, plan }) {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const { show } = useIntercom();
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFeedbackClick = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await get_feedback_url(accessToken);
      // Open the feedback URL in a new tab or window
      window.open(response.data.url, "_blank");

      // Close the menu
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    handleClose();
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <ListItemButton
        sx={{
          ...itemStyle,
          // Apply the same background color used on hover
          backgroundColor: (theme) => theme.palette.primary.light,
        }}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: "2.5rem" }}>
          <Avatar sx={{ ...avatarStyle }} src={user.picture} alt={user.name} />
        </ListItemIcon>
        <ListItemText
          primary={user.name}
          primaryTypographyProps={{
            ...textStyle,
            noWrap: true,
          }}
          sx={{
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        <ListItemIcon sx={{ minWidth: "auto", ml: "auto" }}>
          {getIcon("arrowUp", theme.palette.text.primary, "small")}
        </ListItemIcon>
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // Transform the menu so that it "grows" upwards from the anchor point
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: "200px",
              borderRadius: 2,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: -1.5, // Adjust this margin to place the menu slightly above the anchor
              padding: 1,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: 0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        {/* Top User Section */}
        <Box sx={{ p: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {user.email}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
            <Avatar
              sx={{ ...avatarStyle }}
              src={user.picture}
              alt={user.name}
            />
            <Box ml={1}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Personal
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                textTransform={"capitalize"}
              >
                {plan}
              </Typography>
            </Box>
          </Box>
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    mt: 1,*/}
          {/*    p: 1,*/}
          {/*    borderRadius: 2,*/}
          {/*    backgroundColor: theme.palette.secondary.light,*/}
          {/*    textAlign: "center",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant="caption">*/}
          {/*    Get more from LRND with Pro plan.*/}
          {/*  </Typography>*/}
          {/*  <Box mt={0.5}>*/}
          {/*    <Link*/}
          {/*      component="button"*/}
          {/*      variant="caption"*/}
          {/*      sx={{ fontWeight: "bold", color: theme.palette.primary.main }}*/}
          {/*      onClick={() => {*/}
          {/*        handleClose();*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Upgrade Plan*/}
          {/*    </Link>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* Other Menu Items */}
        <MenuItem
          onClick={() => {
            onClickAction(userActions.profile);
            handleClose();
          }}
          sx={{ my: 1, paddingLeft: "8px", paddingRight: "8px" }}
        >
          <ListItemIcon sx={{ minWidth: "auto", mr: "auto" }}>
            {getIcon(
              "settings",
              theme.palette.text.primary,
              "small",
              "smallIcon",
            )}
          </ListItemIcon>
          <ListItemText
            primary="Account Settings"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleFeedbackClick();
            handleClose();
          }}
          sx={{ my: 1, paddingLeft: "8px", paddingRight: "8px" }}
        >
          <ListItemIcon
            sx={{
              minWidth: "auto",
              mr: "auto",
            }}
          >
            {getIcon("bug", theme.palette.text.primary, "small", "smallIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Report Bugs"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
          <ListItemIcon
            sx={{
              minWidth: "auto",
              ml: "auto",
              "&.MuiListItemIcon-root": {
                minWidth: "auto",
              },
            }}
          >
            {getIcon(
              "redirect",
              theme.palette.text.primary,
              "small",
              "smallIcon",
            )}
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleFeedbackClick();
            handleClose();
          }}
          sx={{ my: 1, paddingLeft: "8px", paddingRight: "8px" }}
        >
          <ListItemIcon
            sx={{
              minWidth: "auto",
              mr: "auto",
            }}
          >
            {getIcon("add", theme.palette.text.primary, "small", "smallIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Feature Request"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
          <ListItemIcon
            sx={{
              minWidth: "auto",
              ml: "auto",
              "&.MuiListItemIcon-root": {
                minWidth: "auto",
              },
            }}
          >
            {getIcon(
              "redirect",
              theme.palette.text.primary,
              "small",
              "smallIcon",
            )}
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => {
            show();
            handleClose();
          }}
          sx={{ my: 1, paddingLeft: "8px", paddingRight: "8px" }}
        >
          <ListItemIcon sx={{ minWidth: "auto", mr: "auto" }}>
            {getIcon("help", theme.palette.text.primary, "small", "smallIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Get Help"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://support.lrnd.ai", "_blank");
            handleClose();
          }}
          sx={{ my: 1, paddingLeft: "8px", paddingRight: "8px" }}
        >
          <ListItemIcon sx={{ minWidth: "auto", mr: "auto" }}>
            {getIcon(
              "help_center",
              theme.palette.text.primary,
              "small",
              "smallIcon",
            )}
          </ListItemIcon>
          <ListItemText
            primary="Help Center"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
          <ListItemIcon
            sx={{
              minWidth: "auto",
              ml: "auto",
              "&.MuiListItemIcon-root": {
                minWidth: "auto",
              },
            }}
          >
            {getIcon(
              "redirect",
              theme.palette.text.primary,
              "small",
              "smallIcon",
            )}
          </ListItemIcon>
        </MenuItem>

        <Divider sx={{ my: 1 }} />

        <MenuItem onClick={handleLogout} sx={{ mt: 1 }}>
          <ListItemIcon sx={{ minWidth: "auto", mr: "auto" }}>
            {getIcon("exit", theme.palette.text.primary, "small", "smallIcon")}
          </ListItemIcon>
          <ListItemText
            primary="Log Out"
            primaryTypographyProps={{
              fontSize: ".875rem",
              lineHeight: "1.25rem",
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

UserActions.propTypes = {
  shouldHighlight: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  userActions: PropTypes.object.isRequired,
  plan: PropTypes.string.isRequired,
};

const itemStyle = {
  borderRadius: "0.75rem",
  margin: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(2),
  marginTop: 2,
  paddingLeft: "1rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  "&:hover": {
    backgroundColor: (theme) => theme.palette.primary.light,
  },
};

const avatarStyle = {
  backgroundColor: (theme) => theme.palette.primary.main,
  width: 24,
  height: 24,
  borderRadius: 2,
};

const textStyle = {
  fontSize: "0.75rem",
  fontWeight: "bold",
};
