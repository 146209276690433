import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import SearchIcon from "@mui/icons-material/Search";

const sources = [
  {
    key: "tutor",
    title: "Module Tutor",
    buttonText: "Tutor",
    icon: (
      <LocalLibraryOutlinedIcon sx={{ width: "0.85em", height: "0.85em" }} />
    ),
  },
  {
    key: "search",
    title: "Search the web",
    buttonText: "Search",
    icon: <SearchIcon sx={{ width: "0.85em", height: "0.85em" }} />,
  },
  {
    key: "ai_knowledge",
    title: "AI knowledge",
    buttonText: "AI",
    icon: (
      <AutoAwesomeOutlinedIcon sx={{ width: "0.85em", height: "0.85em" }} />
    ),
  },
];

export default sources;
